import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { useHistory } from "react-router-dom"
import { HubConnectionBuilder } from "@microsoft/signalr"
import packageJson from '../../package.json'
import { v4 as uuidv4 } from 'uuid'

import { GetLanguageId, SetLanguageId, ResetLanguageId, Translate } from "./Translator"
import { LookupApiGetSearchHistory, LookupApiUpdateSearchHistory, LookupApiGetSpeedDials, LookupApiUpdateSpeedDials, LookupApiClientReplicatorInfo, LookupApiLookupNumber } from "../ExternalApi/LookupApi"
import { PhoneApiGetPhoneSettings, PhoneApiSetPhoneSettings, PhoneApiSubscribe, PhoneApiMakeCall } from "../ExternalApi/PhoneApi"
import { PushApiRegisterUserAsWebclientUser, PushApiSubscribe, PushApiUnsubscribe, PushApiHasSubscription, PushApiGetCallHistory, PushApiCallHistoryDeleteCall, PushApiCallHistoryAddOutboundCall } from "../ExternalApi/PushApi"

import { NowInDateTimeFormat, IsEmptyString, UrlBase64ToUint8Array } from "./GeneralFunctions"

import CallNotification from "./CallNotification"
import UserSettingsModal from "./UserSettingsModal"
import CallHistory from "./CallHistory"
import SearchArea from "./SearchArea"
import SpeedDials from "./SpeedDials"

import CloudCTIPng from '../Assets/CloudCTI.png'
import SettingsUserPng from '../Assets/settings_user.png'
import SignOutPng from '../Assets/signout.png'

import "../css/Main.css"

//Subclasses

var sforce

const Main = (props) => {

  const mRef = React.createRef()
  
  //Push
  const cPushPublicVapidKey = "BJovlKvWRuqeCSbyyogJUuJZ3iOPlwF1JagAADr3E7zqtNb-c-oKm1MB3eptSuOuoNWlVfkBDgiPriKBU0N7ifI"

  const mHistory = useHistory();
  const [mRepainter, SetRepainter] = useState(false)

  //const mSecurityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJhbmRyZXRlc3RwYnhAY2xvdWRjdGkubmwiLCJTaWduaW5QYXNzd29yZCI6IndPZXpYZXg0Z3VuRG1QbXFmcno5RkdKVnJseVNqR2hoS0s4bDNaZHNOcVBxK3M3L2tJQWVPb3RRYkdQUWZlN1NFWXgyRGZ5SVB0VitYWE53THZEN05lQ0NNQ0UyWjBhNHBCL25VL2JBMk1MKytQeEt6RXZ5NGhPSWU0RWtseE80IiwiU2lnbmluQXBwbGljYXRpb24iOiJXZWJDbGllbnQyMDE4IiwiVGVuYW50R3VpZCI6IlRlbmFudF82MThiY2FiYi00NmRhLTQyZWItYWY5NC05ZDg1YzI3NjdlMzQiLCJUZW5hbnROYW1lIjoiS2V5bGluayIsIlVzZXJHdWlkIjoiVGVuYW50VXNlcl82ZmM5OGMzZS00ZmNkLTQ2M2ItOTljMS0yZDAxM2M2NzBlZjEiLCJVc2VyTmFtZSI6IkFuZHJlIiwiVXNlcklzQWRtaW4iOiIxIiwiVXNlcklzVGVuYW50QWRtaW4iOiIwIiwiRW1haWwiOiJhbmRyZXRlc3RwYnhAY2xvdWRjdGkubmwiLCJMaWNlbmNlTmFtZSI6IkFuZHJlIFRlc3QgUEJYIiwiTGljZW5jZVBsYXRmb3JtcyI6IkFuZHJlVGVzdCIsIlJlY29nbml0aW9uQW5kU2NyZWVucG9wIjoiMSIsIlJvbGVzIjoiVXNlcixDYWxsQ29udHJvbCxBbmRyZVRlc3QiLCJleHAiOjE3Mzk1MjE1NjMsImlzcyI6InNpZ25pbi12YSIsImF1ZCI6IkNsb3VkQ1RJMjAyMiJ9.ENC21rAoioy3aI-yTkTvzo9gzb5gHNjqnuJu86jntAE"
  const mSecurityToken = Cookies.get("webclient")
  const [mSecurityTokenData, SetSecurityTokenData] = useState({})

  const [mVisualMode, SetVisualMode] = useState("FullMode")
  const [mMobileModeWindow, SetMobileModeWindow] = useState(localStorage.getItem("MobileModeWindow") || "Search")

  const [mSearchHistory, SetSearchHistory] = useState([])
  const [mCallHistory, SetCallHistory] = useState([])
  const mCallHistoryRef = useRef(mCallHistory); //Needed because the signalr does not get states
  const [mSpeedDials, SetSpeedDials] = useState([])

  const [mPhoneApiError, SetPhoneApiError] = useState(null)

  const [mPhoneSettings, SetPhoneSettings] = useState(null)
  const [mPhoneSettingsTemplate, SetPhoneSettingsTemplate] = useState("")

  const [mLastCall, SetLastCall] = useState(null)
  const [mLastCallLookupResultIndex, SetLastCallLookupResultIndex] = useState(0)

  const [mLastCallId, SetLastCallId] = useState("")
  const mLastCallIdRef = useRef(mLastCallId); //Needed because the signalr does not get states

  const [mIntegrations, SetIntegrations] = useState([])
  const [mCallfieldScripts, SetCallfieldScripts] = useState([])

  const [mShowModalUserSettings, SetShowModalUserSettings] = useState(false)

  const [mSignalRConnection, SetSignalRConnection] = useState(new HubConnectionBuilder().withUrl("https://eventhub7cloudcti.azurewebsites.net/eventhub/api").withAutomaticReconnect().build()); 

  const [mBrowserNotificationsActive, SetBrowserNotificationsActive] = useState(false)

  const [mEmbeddedScriptInserted, SetEmbeddedScriptInserted] = useState(false) 
  const [mEmbeddedScriptLoaded, SetEmbeddedScriptLoaded] = useState(false) 

  useEffect(() => {

    const handleResize = () => {
      SetVisualMode(GetVisualMode())
    }

    const DecodedToken = jwtDecode(mSecurityToken)
    SetSecurityTokenData({ 
      Email : DecodedToken.Email,
      UserName : DecodedToken.UserName,
      TenantName : DecodedToken.TenantName,
      LicenceName : DecodedToken.LicenceName,
      LicencePlatforms : DecodedToken.LicencePlatforms,
      UserIsAdmin : DecodedToken.UserIsAdmin,
      UserHasRecognitionAndScreenpopLicense : DecodedToken.RecognitionAndScreenpop === "1"}
    )

    if (mSignalRConnection) {
      mSignalRConnection
        .start()
        .then(() => {
          mSignalRConnection.onclose(() => { OnSignalRError("Connection closed"); })
        })
        .then(() => {
          mSignalRConnection.onreconnected(() => { mSignalRConnection.invoke("Connect",DecodedToken.Email) })
        })
        .then(() => {
          mSignalRConnection.on("ReceiveMessage", (message) => {
            OnSignalREvent(message);
          });
        })
        .then(() => {
          mSignalRConnection.invoke("Connect",DecodedToken.Email)
        })
        .catch((error) => OnSignalRError(error));
    }

    PushApiRegisterUserAsWebclientUser(mSecurityToken)

    LookupApiClientReplicatorInfo(mSecurityToken).then((clientreplicatorInfo) => { SetIntegrations(clientreplicatorInfo.data.ReplicatorNames); SetCallfieldScripts(clientreplicatorInfo.data.CallFieldScriptNames) })
   
    PushApiGetCallHistory(mSecurityToken).then((callHistoryResult) => { SetCallHistoryWithRef(callHistoryResult)})
    LookupApiGetSearchHistory(mSecurityToken).then((searchHistory) => { SetSearchHistory(searchHistory)})
    LookupApiGetSpeedDials(mSecurityToken).then((speedDials) => { SetSpeedDials(speedDials)})    

    PhoneApiSubscribe(mSecurityToken).then((result) => ProcessPhoneApiSubscribeResult(result))    
    
    PushApiHasSubscription(mSecurityToken).then((result) => ProcessPushApiHasSubscriptionResult(result))

    ResetLanguageId()

    SetVisualMode(GetVisualMode())

    if (props.Embedded !== "")
    {   
      InsertEmbeddedSalesforce()
    }

    window.addEventListener('resize', handleResize);
    return () => { 
      window.removeEventListener('resize', handleResize)
      //try { document.body.removeChild(script) } catch {}
    }
  }, []); 

  const SetCallHistoryWithRef = (callhistory) => {
    SetCallHistory(callhistory)
    mCallHistoryRef.current = callhistory
  }

  const InsertEmbeddedSalesforce = () => {

    /*
    const script = document.createElement('script')
    script.src = props.Embedded === "salesforce" ? "../Embedded/Salesforce/opencti_min.js" : ""
    script.type = "text/jsx"
    script.async = true
    //document.body.appendChild(script)
    //document.getElementsByTagName('head')[0].insertBefore(node, document.getElementsByTagName('script')[0])
    document.body.appendChild(script)
    SetEmbeddedScriptInserted(true)
    */
  }

  const LoadEmbeddedSalesforce = () => {
    /*
    try 
    {
      let onClickToDialListener = (payload) => 
      {
        try 
        {
          console.log('Salesforce clicked to dial: ' + JSON.stringify(payload))
        }
        catch
        {
          console.log("Failed to execute the makecall request.");
        }
      }
  
      console.log("sforce")
      console.log(sforce)
      sforce.opencti.onClickToDial({ listener: onClickToDialListener }).then(SetEmbeddedScriptAdded(true))
    }
    catch (e)
    {
      console.log("Unable to set onClickToDial listener: " + e)
    }
      */
  } 


  const AddToSearchHistory = (aNumber, aName, aApplication, aScripts) => {
    try 
    {  
      const ExistingItems = mSearchHistory && (mSearchHistory.length > 0) ?  mSearchHistory
        .filter((item,index) => (item.Number !== aNumber))
        .filter((item,index) => (index < 9)) : []
      const SearchHistory = [{ Number: aNumber, Name:aName, Application:aApplication, Scripts:aScripts},...ExistingItems]
      LookupApiUpdateSearchHistory(mSecurityToken, SearchHistory)
        .then(() => LookupApiGetSearchHistory(mSecurityToken))
        .then((searchHistory) => { SetSearchHistory(searchHistory)})
    } 
    catch (ex) 
    {    
      console.log("AddToLastSearches failed")
      console.log(ex)
    }
  }

  const AddToSpeedDial = async (aNumber, aName, aApplication, aScripts) => 
  {
    try 
    {  
      let SpeedDials = (mSpeedDials === undefined) || (mSpeedDials === null) ? [] : mSpeedDials
      SpeedDials = [...SpeedDials,...[{ Number: aNumber, Name:aName, Application:aApplication, Scripts:aScripts}]]
      LookupApiUpdateSpeedDials(mSecurityToken, SpeedDials)
        .then(() => LookupApiGetSpeedDials(mSecurityToken))
        .then((speedDials) => { SetSpeedDials(speedDials)})
    } 
    catch (ex) 
    {    
      let error = ex;
      console.log("AddToSpeedDial failed: " + error);
    }
  }

  const ApplyPhoneSettings = (data) => {
    SetPhoneSettingsTemplate(data.SettingsTemplate)
    SetPhoneSettings(data.PhoneSettings)
  }

  const ChangeLanguageId = (languageid) => {
    SetLanguageId(languageid)
    SetRepainter(!mRepainter)
  }

  const DisableBrowserNotifications = () =>
  {
    PushApiUnsubscribe(mSecurityToken)
  }
  
  const EnableBrowserNotifications = async () =>
  {
    const result = await Notification.requestPermission();
    if (result === 'denied') 
    {
      console.error('The user explicitly denied the permission request.');
      return;
    }
    else if (result === 'granted') 
    {
      console.info('The user accepted the permission request.');
    }

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      const registration = registrations[0]
      console.log(registration)
      registration.pushManager.getSubscription().then((subscription) => {
        if (subscription) 
        {
          console.log('Service worker already subscribed')
          const key = subscription.getKey("p256dh")
          const encodedKey = key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null;
          const auth = subscription.getKey("auth")
          const encodedAuth = auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null;
          PushApiSubscribe(mSecurityToken, "", subscription.endpoint, encodedKey, encodedAuth).then(() => SetBrowserNotificationsActive(true))  
        }
        else
        {
          registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: UrlBase64ToUint8Array(cPushPublicVapidKey) })
            .then((subscription) => {
              console.log('Service worker subscribe succeeded:', subscription)
              const key = subscription.getKey("p256dh")
              const encodedKey = key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null;
              const auth = subscription.getKey("auth")
              const encodedAuth = auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null;
              PushApiSubscribe(mSecurityToken, "", subscription.endpoint, encodedKey, encodedAuth).then(() => SetBrowserNotificationsActive(true))    
            })
        }
      })
    })
  }

  const GetVisualMode = () => {
    return (window.innerWidth < 740) ? "MobileMode" : (window.innerWidth < 930 ? "CompactModeNoHistorySearch" : (window.innerWidth < 1260 ? "CompactMode" : "FullMode"))
  }

  const MakeCall = (aNumber, aName, aApplication, aScripts) => {
    try 
    {  
      //1. Do the actual makecall
      PhoneApiMakeCall(mSecurityToken, aNumber)

      //2. Make up a callid
      const CallId = uuidv4()

      //3. Save the call locally in the call history 
      SetCallHistoryWithRef([...[{ CallId: CallId, Number: aNumber, Name: aName, Application: aApplication, Scripts: aScripts, Incoming: false, DateTime: NowInDateTimeFormat() }],...mCallHistory])
      
      //4. Save the call online
      PushApiCallHistoryAddOutboundCall(mSecurityToken, CallId, aNumber, aName, aApplication, aScripts, false)
    } 
    catch (ex) 
    {    
      console.log("MakeCall failed")
      console.log(ex)
    }
  }  

  const MobileModeWindowChange = (aWindow) =>
    {
      SetMobileModeWindow(aWindow)
      localStorage.setItem("MobileModeWindow",aWindow)
  }

  const OpenWebConfigurator = () => 
  {
    window.open("https://webconfigurator.cloudcti.nl?handover_token=" + mSecurityToken)
  } 

  const ProcessPhoneApiSubscribeResult = (result) => {
    SetRepainter(!mRepainter)
    if ((result.status !== undefined) && (result.status === 200))
    {
      SetPhoneApiError("")
    }
    else
    {
      let Error = result.message
      if (result.response && result.response.data && result.response.data.Exception && result.response.data.Exception.Message)
      {        
        try { Error = result.response.data.Exception.Message } catch {}
      }
      else if (result.response && !IsEmptyString(result.response.data))
      {
        try { Error = result.response.data } catch {}
      }
      SetPhoneApiError(Error)
    }

    PhoneApiGetPhoneSettings(mSecurityToken).then((result) => ProcessPhoneApiGetSettingsResult(result)) 
  }

  const ProcessPhoneApiGetSettingsResult = (result) => {
    if ((result.status !== undefined) && (result.status === 200))
    {
      //console.log("ProcessPhoneApiGetSettingsResult ApplyPhoneSettings")
      //console.log(result.data)
      ApplyPhoneSettings(result.data)
    }
    else
    {
      let Error = result.message
      if (result.response && result.response.data && result.response.data.Exception && result.response.data.Exception.Message)
      {        
        try { Error = result.response.data.Exception.Message } catch {}
      }
      else if (result.response && !IsEmptyString(result.response.data))
      {
        try { Error = result.response.data } catch {}
      }
      SetPhoneApiError(Error)
    }
  }

  const ProcessPushApiHasSubscriptionResult = (result) => {
    if ((result.status === 200) && (result.data.Subscribed))
    { 
      EnableBrowserNotifications().then(() => SetBrowserNotificationsActive(true))
    }
    else
    {
      SetBrowserNotificationsActive(false)
    }
  }

  const ProcessPhoneApiSetSettingsResult = (result) => {
    if ((result.status !== undefined) && (result.status === 200))
    {
      PhoneApiSubscribe(mSecurityToken).then((subresult) => ProcessPhoneApiSubscribeResult(subresult))
    }
    else
    {
      let Error = result.message
      if (result.response && result.response.data && result.response.data.Exception && result.response.data.Exception.Message)
      {        
        try { Error = result.response.data.Exception.Message } catch {}
      }
      else if (result.response && !IsEmptyString(result.response.data))
      {
        try { Error = result.response.data } catch {}
      }
      SetPhoneApiError(Error)
    }
    
  }
  
  const RemoveFromCallHistory = async (aCallId) => 
  {
    try 
    {  
        SetCallHistoryWithRef(mCallHistory.filter(call => (call.CallId !== aCallId))) 
        await PushApiCallHistoryDeleteCall(mSecurityToken, aCallId)
    } 
    catch (ex) 
    {    
        console.log("RemoveFromCallHistory failed");
        console.log(ex);
    }    
  }
    
  const RemoveFromSpeedDial = async (aNumber) => 
  {
    try 
    {  
      const SpeedDials = mSpeedDials.filter((item,index) => item.Number !== aNumber)
      LookupApiUpdateSpeedDials(mSecurityToken, SpeedDials)
        .then(() => LookupApiGetSpeedDials(mSecurityToken))
        .then((speedDials) => { SetSpeedDials(speedDials)})
    } 
    catch (ex) 
    {    
      let error = ex;
      console.log("RemoveFromSpeedDial failed: " + error);
    }
  } 

  const SignOut = () => {
    Cookies.remove('webclient', { domain: props.Domain })
    mHistory.push(packageJson.homepage)
  }  

  const OnSignalRError = (error) => {
    console.log("SignalR eror: " + error)
  }  

  const OnSignalREvent = async (event) => {
    let CallEvent = JSON.parse(event)
    console.log(CallEvent)
    
    if (CallEvent.CallId && (CallEvent.Direction.toLowerCase() === "inbound")) //Only deal with inbound calls here
    {
      if ((CallEvent.State === "ended") || (CallEvent.State === ""))
      {
        let Name = ""
        let ApplicationName = ""
        let Scripts = []
        try
        {
          const result = await LookupApiLookupNumber(mSecurityToken, CallEvent.CallerNumber)

          let FoundRecognition = false
          result.data.Matches.map((match) => 
            {
              if (match.IntegrationGuid && !FoundRecognition)
              {
                let NameField = ""
                match.RecognitionFieldTypes.map((item) => { NameField = NameField ? NameField : (item.value === "Phone number" ? item.name : "")})
                if (NameField)
                {
                  Name = match.RecognitionFields[NameField]
                }

                Name = NameField
                ApplicationName = match.ApplicationName
                Scripts = match.Scripts
                FoundRecognition = true
              }
            })

          result.data.Matches.map((match) => 
            {
              if (!match.IntegrationGuid)
              {
                Scripts = [...Scripts,...match.Scripts]
              }
            })
        }
        catch { }

        let CallHistory = []
        CallHistory.push({ CallId: CallEvent.CallId, Number: CallEvent.CallerNumber, Name: Name, Application: ApplicationName, Scripts: Scripts, Incoming: true, DateTime: NowInDateTimeFormat() })
        mCallHistoryRef.current.map((call) => CallHistory.push(call))
        SetCallHistoryWithRef(CallHistory)

        //Only needed to do this locally. Pushva added the call to the database (this was done so events will still be added when the client is not active)
        //SetCallHistory([...[{ CallId: CallEvent.CallId, Number: CallEvent.CallerNumber, Name: Name, Application: ApplicationName, Scripts: Scripts, Incoming: true, DateTime: NowInDateTimeFormat() }],...mCallHistory])
      }
      else
      {
        if ((mLastCallIdRef.current === "") || (mLastCallIdRef.current !== CallEvent.CallId))
        {
          let Recognitions = []
          let CallfieldScripts = []
          try
          {
            const result = await LookupApiLookupNumber(mSecurityToken, CallEvent.Direction.toLowerCase() === "inbound" ? CallEvent.CallerNumber : CallEvent.CalledNumber)

            result.data.Matches.map((match) => 
            {
              if (match.IntegrationGuid)
              {
                Recognitions.push(match)
              }
              else
              {
                CallfieldScripts = match.Scripts
              }
            })
          }
          catch { }
          CallEvent.Recognitions = Recognitions
          CallEvent.CallfieldScripts = CallfieldScripts

          console.log("Updating notification")

          SetLastCallLookupResultIndex(0)
          SetLastCall(CallEvent)
          mLastCallIdRef.current = CallEvent.CallId
        }
      }
    }   
  } 

  const UpdatePhoneSettings = () => {
    PhoneApiSetPhoneSettings(mSecurityToken,mPhoneSettings).then((result) => ProcessPhoneApiSetSettingsResult(result))  
  }

  //----> HTML

  const ReturnEmbeddedSalesforceMode = () => {
    return <table width="100%" height="100%">        
    <tbody>
    <tr height="*">
      <td colSpan="3" valign="top">
        
        <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr height="30">
            <td valign="center" align="left" className="padded10">
              <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                  {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                  {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                          (mLastCall === null ? Translate("No incoming call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                  }
              </div>  
            </td>
          </tr>   
          </tbody>
        </table>

      </td>
    </tr>
    </tbody>
    </table>
  }

  const ReturnMobileMode = () => {
    return <table width="100%" height="100%">        
    <tbody>
    <tr height="24">
        <td width="33%" className={mMobileModeWindow === "Search" ? "mobilemodewindow mobilemodewindowselected" : "mobilemodewindow"} onClick={() => MobileModeWindowChange("Search")}><span className="up3px">{Translate("Search").toUpperCase()}</span></td>
        <td width="34%" className={mMobileModeWindow === "History" ? "mobilemodewindow mobilemodewindowselected" : "mobilemodewindow"} onClick={() => MobileModeWindowChange("History")}><span className="up3px">{Translate("History").toUpperCase()}</span></td>
        <td width="33%" className={mMobileModeWindow === "Speed dial" ? "mobilemodewindow mobilemodewindowselected" : "mobilemodewindow"} onClick={() => MobileModeWindowChange("Speed dial")}><span className="up3px">{Translate("Speed dial").toUpperCase()}</span></td>
    </tr>
    <tr height="*">

        {mMobileModeWindow === "Search" ? 

            <td colSpan="3" valign="top">
              
              <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr height="30">
                  <td valign="center" align="left" className="padded10">
                    <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                        {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                        {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                                (mLastCall === null ? Translate("No incoming call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                        }
                    </div>  
                  </td>
                </tr>   
                <tr height="*">
                  <td valign="top" align="left">
                    <div className="scrollbary">
                        <SearchArea 
                          SecurityToken={mSecurityToken}
                          SearchHistory={mSearchHistory}
                          OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)}
                          OnAddToSearchHistory={(number, name, application, scripts) => AddToSearchHistory(number, name, application, scripts)}
                        />
                    </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            : (mMobileModeWindow === "History" ? 

            <td colSpan="3" align="left" valign="top">
              <CallHistory 
                Calls={mCallHistory} 
                SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
                ShowHeader={false} 
                AddSearch={true}
                OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)} 
                OnAddToSpeedDial={(number, name, application, scripts) => AddToSpeedDial(number, name, application, scripts)}
                OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)} 
                OnRemoveFromCallHistory={(callid) => RemoveFromCallHistory(callid)}
              />
            </td>

            :

            <td colSpan="3" align="left" valign="top">                      
              <SpeedDials 
                SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
                ShowHeader={false} 
                OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)} 
                RemoveFromSpeedDialEvent={(number) => RemoveFromSpeedDial(number)}
              />
            </td>)
          }
    </tr>
    </tbody>
    </table>
  }

  const ReturnCompactMode = (aAddHistorySearch) => {
    return <table width="100%" height="100%" className="main">        
    <tbody>
    <tr>
        <td width="366" valign="top">

        <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr height="30">
              <td valign="center" align="left" className="padded10">
                <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                    {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                    {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                            (mLastCall === null ? Translate("No incoming call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                    }
                </div>  
              </td>
            </tr>   
            <tr height="*">
              <td valign="top" align="left">
                <SearchArea 
                  SecurityToken={mSecurityToken}
                  SearchHistory={mSearchHistory}
                  OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)}
                  OnAddToSearchHistory={(number, name, application, scripts) => AddToSearchHistory(number, name, application, scripts)}
                />
              </td>
            </tr>
          </tbody>
        </table>

        </td>
        <td width="*" valign="top" className="mainborderleft mainborderright">
          <SpeedDials 
            SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
            ShowHeader={true} 
            OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts, true, false)} 
            RemoveFromSpeedDialEvent={(number) => RemoveFromSpeedDial(number)}
          />
          <CallHistory 
            Calls={mCallHistory} 
            SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
            ShowHeader={true} 
            AddSearch={aAddHistorySearch}
            OnMakeCall={(number) => MakeCall(number)} 
            OnAddToSpeedDial={(number, name, application, scripts) => AddToSpeedDial(number, name, application, scripts)}
            OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)} 
            OnRemoveFromCallHistory={(callid) => RemoveFromCallHistory(callid)}
          />
        </td>
    </tr>
    </tbody>
    </table>    
  }

  const ReturnFullMode = () => {
    return <table width="100%" height="100%">        
      <tbody>
      <tr>        
          <td width="366" valign="top">

          <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr height="30">
                <td valign="center" align="left" className="padded10">
                  <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                      {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                      {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                        (mLastCall === null ? Translate("No incoming call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                      }
                  </div>  
                </td>
              </tr>   
              <tr height="*">
                <td valign="top" align="left">
                  <SearchArea 
                    SecurityToken={mSecurityToken}
                    SearchHistory={mSearchHistory}
                    OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)}
                    OnAddToSearchHistory={(number, name, application, scripts) => AddToSearchHistory(number, name, application, scripts)}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          </td>
          <td width="*" valign="top" className="mainborderleft mainborderright">
            <CallHistory 
              Calls={mCallHistory} 
              SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
              ShowHeader={true} 
              AddSearch={true}
              OnMakeCall={(number) => MakeCall(number)} 
              OnAddToSpeedDial={(number, name, application, scripts) => AddToSpeedDial(number, name, application, scripts)}
              OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)} 
              OnRemoveFromCallHistory={(callid) => RemoveFromCallHistory(callid)}
            />
          </td>
          <td width="339" valign="top">
            <SpeedDials 
              SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
              ShowHeader={true} 
              OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts, true, false)} 
              OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)}
            />
          </td>
      </tr>
      </tbody>
      </table>   
  }

  if (mEmbeddedScriptInserted && !mEmbeddedScriptLoaded)
  {
    LoadEmbeddedSalesforce()
    SetEmbeddedScriptLoaded(true)
  }

  return (
    <span className="main">

      <table className="main" height="100%" width="100%" cellPadding={0}>        
      <tbody>
        <tr height="0">
          <td width="100"/>
          <td width="*"/>
          <td width="100"/>
        </tr>
        <tr height="25" className="topbar"> 
            <td/>
            <td align="center" valign="center">              
                <img src={CloudCTIPng} className="cloudctiicon"/>
            </td>          
            <td align="right" valign="center">           
                <Button variant="outline-info" className="primarybutton link" title="Settings" onClick={() => SetShowModalUserSettings(true)}><img src={SettingsUserPng}/></Button>
                <Button variant="outline-info" className="primarybutton link" title="Sign out" onClick={() => SignOut()}><img src={SignOutPng}/></Button>
                &nbsp;
            </td>   
        </tr>
        <tr height="*"> 
            <td colSpan="3" width="*" align="center" valign="top">

              {props.Embedded === "salesforce" ? ReturnEmbeddedSalesforceMode() : (mVisualMode == "MobileMode" ? ReturnMobileMode() : (mVisualMode == "CompactModeNoHistorySearch" ? ReturnCompactMode(false) : (mVisualMode == "CompactMode" ? ReturnCompactMode(true) : ReturnFullMode())))}

            </td>
        </tr>
        </tbody>
      </table>

      {mShowModalUserSettings ? <UserSettingsModal SecurityToken={mSecurityToken} Email={mSecurityTokenData.Email} UserName={mSecurityTokenData.UserName} TenantName={mSecurityTokenData.TenantName} LicenceName={mSecurityTokenData.LicenceName} LicencePlatforms={mSecurityTokenData.LicencePlatforms} LicenceRecognitionAndScreenpop={mSecurityTokenData.UserHasRecognitionAndScreenpopLicense} LanguageId={GetLanguageId()} Integrations={mIntegrations} CallFieldScripts={mCallfieldScripts} UserIsAdmin={mSecurityTokenData.UserIsAdmin ? true : false} BrowserNotificationsActive={mBrowserNotificationsActive} PhoneSettingsTemplate={mPhoneSettingsTemplate} PhoneSettings={mPhoneSettings} PhoneApiError={mPhoneApiError} OnHide={() => SetShowModalUserSettings(false)} OnSetBrowserNotificationsActive={SetBrowserNotificationsActive} OnUpdatePhoneSettings={() => UpdatePhoneSettings()} OnUpdateLanguage={ChangeLanguageId} OnTranslate={Translate} OnOpenWebConfigurator={() => OpenWebConfigurator()}/> : <span/>}
    
    </span>
  ); 
}

Main.propTypes = {
  ProductName: PropTypes.string,
  Domain: PropTypes.string,
  Embedded: PropTypes.string
}

export default Main;
import axios from "axios"

const cLookupBaseApi = "https://lookup-va.cloudcti.nl/lookupbeta/api/"

//legacy
export async function LookupApiGetCallHistory(aSecurityToken) 
{
    try 
    {
      const result = await axios.get(
        cLookupBaseApi + "userdata/callhistory",
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
      return result.data
    } 
    catch (ex)
    { 
        return [] 
    }
}

/*
export async function LookupApiCallHistoryDeleteCall(aSecurityToken, aId) 
{
    try 
    {
      const result = await axios.delete(
        cLookupBaseApi + "userdata/call?id=" + aId,
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentType: "application/json"
            }
        })
      return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
}
    
export async function LookupApiCallHistoryRegister(aSecurityToken) 
{
    try 
    {
      const result = await axios.post(
        cLookupBaseApi + "userdata/callhistory/register",
        {
        },
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentLength: 0
            }
        })
      return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
}
*/

export async function LookupApiGetSearchHistory(aSecurityToken) 
{
    try 
    {
      const result = await axios.get(
        cLookupBaseApi + "userdata/searchhistory",
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
      return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
}

export async function LookupApiUpdateSearchHistory(aSecurityToken, aSearchHistory) 
{
    try 
    {
      await axios.put(cLookupBaseApi + "userdata/searchhistory",
        aSearchHistory,
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } 
    catch (ex) 
    { 
        console.log("Failed to update SpeedDials: " + ex)
    }
}

export async function LookupApiGetSpeedDials(aSecurityToken) 
{
    try 
    {
      const result = await axios.get(
        cLookupBaseApi + "userdata/speeddials",
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
      return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
}

export async function LookupApiUpdateSpeedDials(aSecurityToken, aSpeedDials) 
{
    try 
    {
      await axios.put(cLookupBaseApi + "userdata/speeddials",
        aSpeedDials,
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } 
    catch (ex) 
    { 
        console.log("Failed to update SpeedDials: " + ex)
    }
}

export async function LookupApiLookupNumber(aSecurityToken, aNumber) 
{
    const result = await axios.get(cLookupBaseApi + "lookupnumber?number=" + aNumber,  
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
    return result
}

export function LookupApiClientReplicatorInfo(aSecurityToken) 
{
    return axios.get(cLookupBaseApi + "clientreplicatorinfo",  
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })
}

export async function LookupApiSearchName(aSecurityToken, aName) 
{
    
    const result = await axios.get(cLookupBaseApi + "searchname?name=" + aName + "&maxresults=10",  
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })
    return result.data
}
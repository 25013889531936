import axios from "axios"
import { NowInDateTimeFormat } from "../Components/GeneralFunctions"

const cPushBaseApi = "https://push-va.cloudcti.nl/pushbeta/"

export async function PushApiRegisterUserAsWebclientUser(aSecurityToken)
{
    try 
    {
        await axios.post(cPushBaseApi + "/webclient/register",  
        {
        },
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        }) 
    } 
    catch (ex)
    { 
        console.log("Failed to register user as webclient user")
        console.log(ex)
    }   
}

export async function PushApiSubscribe(aSecurityToken, aBrandNotificationLogo, aEndPoint, aP256dh, aAuth)
{

    return axios.post(cPushBaseApi + "push/settings",  
    {
        Settings: {
            Endpoint: aEndPoint,
            P256dh: aP256dh,
            Auth: aAuth,
        },
        BrandNotificationLogo: aBrandNotificationLogo
    },
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })    
}

export async function PushApiUnsubscribe(aSecurityToken) 
{
    return axios.delete(cPushBaseApi + "push/settings",  
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })     
}

export async function PushApiHasSubscription(aSecurityToken) 
{
    return axios.get(cPushBaseApi + "push/settings",  
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })     
}

export async function PushApiGetCallHistory(aSecurityToken) 
{
    try
    {
        const result = await axios.get(cPushBaseApi + "callhistory",  
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })     
        return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
} 

export async function PushApiCallHistoryAddOutboundCall(aSecurityToken, aCallId, aNumber, aName, aApplication, aScripts) 
{
    try 
    {
      await axios.post(
        cPushBaseApi + "callhistory/call",
        {
            DateTime:NowInDateTimeFormat(),
            Incoming:false,
            Duration:"",
            Number:aNumber,
            Name: aName !== null ? aName : "",
            Application: aApplication !== null ? aApplication : "", 
            Scripts: aScripts !== null ? aScripts : [],
            CallId: aCallId
        },
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentType: "application/json"
            }
        })
    } 
    catch (ex)
    { 
    }
}

export async function PushApiCallHistoryDeleteCall(aSecurityToken, aCallId) 
{
    try 
    {
        await axios.delete(
            cPushBaseApi + "callhistory/call?callid=" + aCallId,
            {
                headers: {
                    Authorization: "Bearer " + aSecurityToken,
                    Accept: "application/json",
                    ContentType: "application/json"
                }
            }
        )
    } 
    catch (ex)
    { 
        console.log("PushApiCallHistoryDeleteCall failed")
        console.log(ex)
    }
}


import React, { useState } from "react";
import PropTypes from 'prop-types';

import { IsEmptyString, MakeOsLocaleInfoShortDate } from "./GeneralFunctions"
import { Translate } from "./Translator"

import CallHistoryFilter from "./CallHistoryFilter"
import CallHistoryItem from "./CallHistoryItem"

const CallHistory = (props) => {
  
    const [mDirectionFilter, SetDirectionFilter] = useState("")
    const [mTextFilter, SetTextFilter] = useState("")

    const NumberInSpeedDial = (aNumber) =>
    {
        return props.SpeedDials ? props.SpeedDials.filter(speedDial => (speedDial.Number === aNumber)).length > 0 : false
    }

    const FilteredCalls = props.Calls ? props.Calls
        .filter(call => (((call.Incoming ? "Incoming" : "Outgoing") === mDirectionFilter) || (mDirectionFilter === ""))) 
        .filter(call => (((!IsEmptyString(call.Name) && (call.Name.toUpperCase().indexOf(mTextFilter.toUpperCase()) > -1)) || (call.Number.indexOf(mTextFilter) > -1) || (mTextFilter === "")))) 
      : []
    
    return (
      <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
      <tbody>
        <tr height="30" className="sectionheader">
          {props.ShowHeader ? 
          <td width="80" valign="center" align="left" className="sectionheader">
            <span className="up2px">{Translate("History")}</span>
          </td>
          : <td/>
          }
          <td width="*" align="right" className="sectionheader">
            {props.AddSearch ? <input autoComplete="new-password" placeholder={Translate("Search")} value={mTextFilter} type="text" onChange={(event) => { SetTextFilter(event.target.value) }} /> : <span/>}
            {props.AddSearch ? <span>&nbsp;&nbsp;&nbsp;</span> : <span/>}
            <CallHistoryFilter Caption={Translate("All")} Value="" Selected={mDirectionFilter === ""} OnClick={() => SetDirectionFilter("")}/>
            &nbsp;&nbsp;&nbsp;
            <CallHistoryFilter Caption={Translate("Outgoing")} Value="Outgoing" Selected={mDirectionFilter === "Outgoing"} OnClick={() => SetDirectionFilter("Outgoing")}/>
            &nbsp;&nbsp;&nbsp;
            <CallHistoryFilter Caption={Translate("Incoming")} Value="Incoming" Selected={mDirectionFilter === "Incoming"} OnClick={() => SetDirectionFilter("Incoming")}/>
          </td>
        </tr>   
        <tr height="*">
          <td colSpan="2" valign="top" align="left">
            <div className="scrollbary">
              {FilteredCalls.map((call,index) => (
                <div className="contactlistitem" key={index}>
                    <CallHistoryItem 
                        OddRow={index % 2 === 1}
                        Id={call.Id} 
                        Number={call.Number} 
                        Name={call.Name} 
                        Application={call.Application} 
                        Scripts={call.Scripts} 
                        Direction={call.Incoming ? "Incoming" : "Outgoing"} 
                        Time={MakeOsLocaleInfoShortDate(call.DateTime)} 
                        Duration={call.Duration} 
                        CallId={call.CallId} 
                        AlreadySpeedDial={NumberInSpeedDial(call.Number)} 
                        OnMakeCall={(number, name, application, scriptName, screenpopUrl) => props.OnMakeCall(number, name, application, scriptName, screenpopUrl)} 
                        OnAddToSpeedDial={(number, name, application, scripts) => props.OnAddToSpeedDial(number, name, application, scripts)} 
                        OnRemoveFromSpeedDial={(number) => props.OnRemoveFromSpeedDial(number)} 
                        OnDeleteFromCallList={(callid) => props.OnRemoveFromCallHistory(callid)}/></div>
              ))} 
            </div>
          </td>
        </tr>              
      </tbody>
    </table>)
}

CallHistory.propTypes = {
    Calls: PropTypes.array,
    SpeedDials: PropTypes.array,
    ShowHeader: PropTypes.bool,
    AddSearch: PropTypes.bool,
    OnMakeCall: PropTypes.func,
    OnAddToSpeedDial: PropTypes.func,
    OnRemoveFromSpeedDial: PropTypes.func,
    OnRemoveFromCallHistory: PropTypes.func,
}

export default CallHistory